@import 'styles/imports';
@import 'components/Icon/icons-states.scss';

.link {
  display: flex;
  align-items: center;
  color: $color-red;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;

  &.linkSmall { font-size: 11px; }
  &.linkLarge { font-size: 14px; }

  &.link_left {
    .icon {
      margin-right: 6px;
    }
  }
  
  &.link_right {
    flex-direction: row-reverse;
    justify-content: flex-end;
    
    .icon {
      margin-left: 6px
    }
  }

  span {
    border-bottom: 1px solid transparent;
    padding-top: 2px;
  }

  &:hover {
    span {
      border-bottom: 1px solid $color-red;
    }

    .icon_arrow,
    .icon_arrow-left {
      @extend .icon_arrow_hover;
    }

    
  
    .icon_play {
      @extend .icon_play_hover;
    }
  
    .icon_plus {
      @extend .icon_plus_hover;
    }

    .icon_doc {
      @extend .icon_doc_hover;
    }
  }
}

.icon {
  min-width: 24px
}
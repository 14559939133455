@import 'animate.css/animate.min.css';
@import "styles/imports";

.app {
  height: 100%;
}

.page {
  height: 100%;
  overflow: hidden;
  max-width: 100vw;
  transform: translateX(0);

  &.page_intro {
    //background-color: $color-cream;

    @include mq('mobile'){
      overflow: visible;
      min-height: 100%;
      height: auto;
    }
  }
}

iframe {
  pointer-events: none;
}
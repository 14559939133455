@import 'styles/imports';

.wrap {
  padding: 20px 46px 0 48px;
  position: relative;
  z-index: 3;
}

.title {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: $color-red;
  padding-left: 43px;
}

.bar {
  position: relative;
  height: 2px;
  background: $color-red;
  position: relative;
  width: 100%;
  display: block;
  z-index: 1;


  .startIcon {
    position: absolute;
    left: 0;
    width: 2px;
    top: -4px;
    height: 10px;
    background-color: $color-red;
  }

  .endIcon {
    position: absolute;
    top: -8.5px;
    right: -3px;
  }
}

.timeline {
  display: grid;
  grid-template-columns: 175px 6% 6% 6% 20% 20% 20% 6% 6%;
  margin-top: -9px;
}

.period {
  position: relative;
  top: -12px;
  z-index: 2;

  &.periodActive {
    z-index: 3;
  }

  &:before {
    content: "";
    position: absolute;
    height: 28px;
    width: 2px;
    background: $color-red;
    top: 7px
  }

  &:first-child {
    &::before { display: none; }
  }
}

.year {
  font-size: 11px;
  font-style: italic;
  font-weight: 500;
  line-height: 21px;
  padding-left: 7px;

  &.yearFirst {
    padding-left: 0;
  }
}

.milestone {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 25px;
  align-items: center;
  z-index: 4;

  &.m1 { left: 7% }
  &.m2 { left: 17% }
  &.m3 { left: 22% }
  &.m4 { left: 30% }
  &.m5 { left: 36% }
  &.m6 { left: 45% }
  &.m7 { left: 52% }
  &.m8 { left: 60% }
  &.m9 { left: 70% }
  &.m10 { left: 80% }
  &.m11 { left: 85% }
  &.m12 { left: 90% }

  &.milestoneActive {
    z-index: 2;
  }
}

.marker {
  margin-bottom: 4px;

  &.markerActive path { fill: $color-milestone-lightblue }

  &.marker_red {
    path:nth-child(2) { fill: $color-red }
    &.markerActive path { fill: $color-red }
  }
  &.marker_orange {
    path:nth-child(2) { fill: $color-milestone-orange; }
    &.markerActive path { fill: $color-milestone-orange }
  }
  &.marker_green {
    path:nth-child(2) { fill: $color-milestone-green; }
    &.markerActive path { fill: $color-milestone-green }
  }

  &.markerActive {
    position: relative;
    z-index: 105;
  }
}

.markerPlusIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;

  &.markerPlusIconActive {
    position: relative;
    z-index: 105;
  }
}

.nowCircle {
  width: 12px;
  height: 12px;
  background-color: $color-red;
  position: absolute;
  border-radius: 50%;
  top: 30px;
  //left: 11%;
  z-index: 3;

  &.nowCircle_1 { left: 4% }
  &.nowCircle_2 { left: 13% }
  &.nowCircle_3 { left: 22% }
  &.nowCircle_4 { left: 28% }
  &.nowCircle_5 { left: 32% }
  &.nowCircle_6 { left: 42% }
  &.nowCircle_7 { left: 50% }
  &.nowCircle_8 { left: 58% }
  &.nowCircle_9 { left: 68% }
  &.nowCircle_10 { left: 78% }
  &.nowCircle_11 { left: 82% }
  &.nowCircle_12 { left: 88% }
}

.tbc {
  position: absolute;
  color: $color-red;
  font-size: 11px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 1px;
  right: 46px;
  top: 23px;
}
@import 'styles/imports';
@import 'components/Icon/icons-states.scss';

.wrap {
  padding-left: 150px;
  position: relative;
  z-index: 2;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 150px;
  height: 40px;
  cursor: pointer;
  position: relative;
  max-width: 350px;

  &.itemBrand {
    &:hover .arrowIcon { @extend .icon_arrow_hover; }
  }

  &.itemPlanet {
    .itemLabel {color: $color-planet-green; }
    .arrowIcon { @extend .icon_arrow_green; }
    &:hover .arrowIcon { @extend .icon_arrow_green_hover; }
  }

  &.itemCulture {
    margin-top: 183px;

    .itemLabel { color: $color-culture-orange; }
    
    .arrowIcon { @extend .icon_arrow_orange; }
    &:hover .arrowIcon { @extend .icon_arrow_orange_hover; }
  }
  
  &.itemStrategy {
    .itemLabel { color: $color-strategy-blue; }
    
    .arrowIcon { @extend .icon_arrow_blue; }
    &:hover .arrowIcon { @extend .icon_arrow_blue_hover; }
  }
}

.itemLabel {
  font-size: 28px;
  font-weight: 500;
  color: $color-red;
  text-transform: uppercase;
  margin-right: 8px;
}

.iconWrap {
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.icon {}

.arrowIcon {
  position: relative;
  top: -1px;
}

:global {
  .sectionsList-enter {
    opacity: 0;
  }
  .sectionsList-enter-active {
    opacity: 1;
    //animation: fadeInWithDelay 500ms ease 0s;
    transition: opacity 500ms;
    transition-delay: 1.5s;
  }
  .sectionsList-exit {
    opacity: 1;
  }
  .sectionsList-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
}

@keyframes fadeInWithDelay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
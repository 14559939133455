@import 'styles/imports';

.wrap {
  min-width: 265px;
  max-width: 265px;
  padding: 54px 0 0;
}

.logoLarge {
  width: 72px;
  height: 67px;
}

.content {
  padding-left: 37px;
}

.title {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: $color-red;
  margin: 11px 0 0;
  max-width: 195px;
}

.text {
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  color: $color-black;
  white-space: pre-line;
  margin: 12px 0 0;
}

.world {
  margin-top: 30px;
}

.play {
  margin-top: 22px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: $color-red;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
}

.links {
  margin-top: 4.5rem;

  a:first-child {
    white-space: nowrap;
    margin-left: -15px;
    margin-right: -10px;
  }

  div {
    span {
      font-size: 13px;
    }
  }
}

@import 'styles/imports';
@import './icons-states.scss';

.icon {
  &.icon_arrow {
    &:hover { @extend .icon_arrow_hover; }
  }

  &.icon_play {
    &:hover { @extend .icon_play_hover; }
  }

  &.icon_plus,
  &.icon_plus2 {
    &:hover { @extend .icon_plus_hover; }
  }

  &.icon_doc {
    &:hover { @extend .icon_doc_hover; }
  }

  &.icon_close {
    &:hover { @extend .icon_close_hover; }
  }

  &.iconHighlight {
    z-index: 1005;
    &.icon_plus,
    &.icon_plus2 { @extend .icon_plus_hover; }
  }
}
@import 'styles/imports';

.wrap {
  position: relative;
  margin-right: 35px;
  &:last-child { margin-right: 0 }
}

.links {
  margin-top: 6rem;
  display: flex;
}

.link {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
  color: $color-red;
  display: flex;
  align-items: center;

  .linksLarge & {
    font-size: 14px;
  }
}

.icon {
  height: 24px;
  margin-left: 6px;
}
@import "styles/imports";

.wrap {
  //padding: 64px 71px;

  &.wrapVideo {
    padding: 0;
  }
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: $color-red;
  margin: 0 0 16px;
  text-transform: uppercase;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 0 16px;
  white-space: pre-wrap
}


@import 'styles/imports';

.container {
  &.animateIn {
    animation: containerDown 2s ease 0s;
    transform: translate(0, 500px);
  }

  &.animateOut {
    animation: containerUp 2s ease 0s;
    transform: translate(0, 0);
  }
}

.leftSide {
  align-self: flex-end;
  position: relative;
  bottom: 5px;
  min-width: 501px;

  .animateIn & { animation: cloudUp 1.8s ease 0s }

  :global(.brand-exit-active) & { 
    animation: hide 1.7s ease 0s;
    transform: scale(0);
  }
  :global(.brand-exit-done) & { transform: scale(0); }
  //.exiting & {  transform: scale(0); }

  :global(.brand-enter) & { transform: scale(0); }
  :global(.brand-enter-active) & { animation: show 2s ease .3s; }
  :global(.brand-enter-done) & { transform: scale(1); }
}

.rightSide {
  margin-left: auto;

  .animateIn & { animation: cloudUp 1.8s ease 0s }

  :global(.brand-exit-active) & { animation: hide 1.7s ease 0s }
  :global(.brand-exit-done) & { transform: scale(0) }
  //.exiting & {  transform: scale(0); }

  :global(.brand-enter) & { transform: scale(0); }
  :global(.brand-enter-active) & { animation: show 2s ease .3s }
  :global(.brand-enter-done) & { transform: scale(1) }
}


.planeAnimation {
  position: absolute;
  top: -206px;
  right: 94px;

  .animateIn & { animation: upAndDown 1.8s ease 0s; }

  :global(.brand-exit-active) & { animation: hide 1.7s ease }
  :global(.brand-exit-done) & { transform: scale(0); }
  //.exiting & {  transform: scale(0); }

  :global(.brand-enter) & { transform: scale(0); }
  :global(.brand-enter-active) & { animation: show 2s ease .3s }
  :global(.brand-enter-done) & { transform: scale(1); }
}

.umbrellaAnimation {
  position: absolute;
  top: -20px;
  left: -104px;

  .animateIn & { animation: upAndDown 1.8s ease 0s; }
  
  :global(.brand-exit-active) & { animation: hide 1.2s ease }
  :global(.brand-exit-done) & { transform: scale(0); }

  :global(.brand-enter) & { transform: scale(0); }
  :global(.brand-enter-active) & { animation: show 2s ease .3s }
  :global(.brand-enter-done) & { transform: scale(1); }
  //.exiting & {  transform: scale(0); }
}

@keyframes upAndDown {
  0% {
    transform: scale(1) translate(0, 0);
  }
  20% {
    transform: scale(1.05) translate(0, -40px);
  }
  75% {
    transform: scale(1) translate(0, 0);
  }
}

@keyframes containerDown {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(0, 0);
  }
  80%{
    transform: translate(0, 500px);
    bottom: 0;
  }
}

@keyframes containerUp {
  0% {
    transform: translate(0, 500px);
  }
  20% {
    transform: translate(0, 500px);
  }
  80% {
    transform: translate(0, 0);
    bottom: 0;
  }
}

@keyframes cloudUp {
  0% {
    transform: translate(0, 0);
  }
  35% {
    transform: translate(0, -30px);
  }
  90% {
    transform: translate(0, 0);
  }
}

@keyframes hide {
  0% {
    transform: scale(1);
  }
  30% { 
    transform: scale(1.05);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes show {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes hidePlane {
  0% {
    transform: scale(1) translate(0, 0);
  } 
  10% {
    transform: scale(.9) translate(100px, 100px);
  }
  20% {
    transform: scale(.8) translate(200px, 200px);
  }
  50% {
    transform: scale(.6) translate(540px, 540px);
  }
  70% {
    transform: scale(.3) translate(720px, 720px);
  }
  100% {
    transform: scale(0) translate(1000px, 800px);
  }
}
@import 'styles/imports';

.wrap {
  display: flex;
  //align-items: center;
  padding-bottom: 6px;
  min-height: 55px;
}

.icon {
  //margin-right: 6px;
  min-width: 26px;
  position: relative;
  top: -2px;
  
  svg {
    width: 26px;
    height: 26px;
  }
}

.range {
  display: flex;
  min-width: 125px;
  margin-left: 5px;
  flex-direction: column;
}

.year {
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 9px;
  padding-bottom: 2px;
  padding-left: 70px;
  white-space: pre;

  .wrapEmissions & {
    padding-left: 0;
    text-align: right;
    position: relative;

    &:before {
      content: "";
      height: 13px;
      width: 1px;
      position: absolute;
      background-color: #000;
      top: 12px;
      right: 40px;
    }
  }
}

.label {
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  color: $color-red;
  padding-top: 5px;
}

.plus {
  margin-left: 12px;
  position: relative;
  //top: -8px;
  cursor: pointer;
}

.plusWrap {
  position: relative;
}
@import 'styles/imports';

.icon_arrow_hover {
  path:first-child { fill: #FA1439 }
  path:last-child { stroke: #fff }
}

.icon_arrow_green {
  path { stroke: $color-planet-green }
}

.icon_arrow_green_hover {
  path:first-child { fill: $color-planet-green; }
  path:last-child { stroke: #fff }
}


.icon_arrow_orange {
  path { stroke: $color-culture-orange }
}

.icon_arrow_orange_hover {
  path:first-child { fill: $color-culture-orange; }
  path:last-child { stroke: #fff }
}


.icon_arrow_blue {
  path { stroke: $color-strategy-blue }
}

.icon_arrow_blue_hover {
  path:first-child { fill: $color-strategy-blue; }
  path:last-child { stroke: #fff }
}


.icon_play_hover {
  path:first-child { fill: #FA1439 }
  path:last-child { fill: #fff }
}

.icon_plus_hover {
  path:nth-child(1) { fill: #FA1439 }
  path:nth-child(2),
  path:nth-child(3) {
    stroke: #fff;
  }
}

.icon_doc_hover {
  path:first-child {
    fill: $color-red;
  }
  rect {
    fill: $color-red;
    stroke: #fff
  }
}

.icon_close_hover {
  path:nth-child(1) {
    fill: black;
    stroke: black;
  }
  path:nth-child(2),
  path:nth-child(3) {
    stroke: white
  }
}
@import 'styles/imports';

.container {
  &:global(.planet-exit-active) { animation: containerDown 1.7s ease 0s; }
  &:global(.planet-exit-done) { transform: translate(0, 650px); }

  &:global(.planet-enter-active) { animation: containerUp 1.7s ease 0s; }
  &:global(.planet-enter-done) { transform: translate(0, 0); }

  &.animateIn {
    animation: containerDownActive 1.4s ease 0s;
    transform: translate(0, 387px);
  }

  &.animateOut {
    animation: containerUpActive 1.5s ease 0s;
    transform: translate(0, 0);
  }
}

.leftSide {
  position: absolute;
  min-width: 395px;
  max-width: 395px;
  left: 57px;
  bottom: 0;
  z-index: 2;
  :global(.planet-exit-active) & { animation: sideUp 1.7s ease 0s; }
  
  .animateIn & { animation: sideUp 1.7s ease 0s }
}

.rightSide {
  margin-left: auto;
  min-width: 669px;
  position: absolute;
  right: 1px;
  top: -4px;
  z-index: 1;

  :global(.planet-exit-active) & { animation: sideUp 1.7s ease 0s; }
  
  .animateIn & { animation: sideUp 1.7s ease 0s }
}

.carAnimation {
  position: absolute;
  left: 113px;
  top: 29px;
  z-index: 3;

  :global(.planet-exit-active) & { animation: upAndDown 1.45s ease 0s; }
  .animateIn & { animation: upAndDown 1.45s ease 0s }
}


.cityAnimation {
  position: absolute;
  right: -7px;
  top: -5px;
  z-index: 3;

  :global(.planet-exit-active) & { animation: upAndDown 1.45s ease 0s; }
  .animateIn & { animation: upAndDown 1.45s ease 0s }
}

.batteryAnimation {
  position: absolute;
  left: 24px;
  top: 45px;
  z-index: 3;

  :global(.planet-exit-active) & { animation: upAndDown 1.45s ease 0s; }
  .animateIn & { animation: upAndDown 1.45s ease 0s }
}

@keyframes upAndDown {
  0% {
    transform: translate(0, 0);
  }
  30% {
    transform: translate(0, -25px);
  }
  90% {
    transform: translate(0, 0);
  }
}

@keyframes sideUp {
  0% {
    transform: translate(0, 0);
  }
  40% {
    transform: translate(0, -15px);
  }
  90% {
    transform: translate(0, 0);
  }
}

@keyframes containerDown {
  0% {
    transform: translate(0, 0);
  }
  30% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 650px);
  }
}

@keyframes containerUp {
  0% {
    transform: translate(0, 650px);
  }
  20% {
    transform: translate(0, 650px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes containerDownActive {
  0% {
    transform: translate(0, 0);
  }
  40% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 387px);
  }
}

@keyframes containerUpActive {
  0% {
    transform: translate(0, 387px);
  }
  40% {
    transform: translate(0, 387px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@import 'styles/imports';

.container {
  &:global(.culture-exit-active) { animation: containerDown 1.75s ease 0s; }
  &:global(.culture-exit-done) { transform: translate(0, 475px); }

  &:global(.culture-enter-active) { animation: containerUp 1.75s ease 0s; }
  &:global(.culture-enter-done) { transform: translate(0, 0); }

  &.animateIn {
    animation: containerDownActive 1.4s ease 0s;
    transform: translate(0, 204px);
  }

  &.animateOut {
    animation: containerUpActive 1.5s ease 0s;
    transform: translate(0, 0);
  }
}

.leftSide {
  min-width: 850px;
  position: absolute;
  left: 5px;
  top: -53px;
  height: 258px;
  z-index: 1;

  :global(.culture-exit-active) & { animation: sideUp 1.45s ease 0s }

  .animateIn & { animation: sideUp 1.45s ease 0s }
}

.rightSide {
  position: absolute;
  z-index: 2;
  top: -46px;
  right: 4px;
  height: 279px;

  :global(.culture-exit-active) & { animation: sideUp 1.45s ease 0s }
  
  .animateIn & { animation: sideUp 1.45s ease 0s } 
}

.captainAnimation {
  position: absolute;
  left: -26px;
  top: 58px;
  z-index: 3;

  :global(.culture-exit-active) & { animation: upAndDown 1.45s ease 0s; }
  .animateIn & { animation: upAndDown 1.45s ease 0s }
}

.jugglerAnimation {
  position: absolute;
  right: 305px;
  top: -36px;
  z-index: 3;

  :global(.culture-exit-active) & { animation: upAndDown 1.45s ease 0s; }
  .animateIn & { animation: upAndDown 1.45s ease 0s }
}

.shipAnimation {
  position: absolute;
  right: 104px;
  top: 2px;
  z-index: 3;

  :global(.culture-exit-active) & { animation: upAndDown 1.45s ease 0s; }
  .animateIn & { animation: upAndDown 1.45s ease 0s }
}

@keyframes upAndDown {
  0% {
    transform: translate(0, 0);
  }
  30% {
    transform: translate(0, -15px);
  }
  90% {
    transform: translate(0, 0);
  }
}


@keyframes containerDown {
  0% {
    transform: translate(0, 0);
  }
  30% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 475px);
  }
}

@keyframes containerUp {
  0% {
    transform: translate(0, 475px);
  }
  30% {
    transform: translate(0, 475px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes sideUp {
  0% {
    transform: translate(0, 0);
  }
  40% {
    transform: translate(0, -10px);
  }
  90% {
    transform: translate(0, 0);
  }
}

@keyframes containerDownActive {
  0% {
    transform: translate(0, 0);
  }
  40% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 204px);
  }
}


@keyframes containerUpActive {
  0% {
    transform: translate(0, 204px);
  }
  40% {
    transform: translate(0, 204px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@import 'styles/imports';

.wrap {
  padding: 127px 0 0;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-height: 940px) {
    padding-top: 75px
  }
  @media screen and (max-height: 880px) {
    //padding-top: 60px
  }
  
  &.wrapSection {
    //padding-top: 127px 
  }
}

.wrapInner {
  min-width: 900px;
  max-width: 1200px;
  position: relative;
  flex-grow: 1;
}


:global {
  .content-enter {
    opacity: 0;
  }
  .content-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  .content-exit {
    opacity: 1;
  }
  .content-exit-active {
    transition: opacity 500ms;
    opacity: 0;
  }
}

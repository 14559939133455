@import 'styles/imports';

.wrap {
  min-width: 265px;
  max-width: 265px;
  margin-left: auto;
  padding: 45px 0 35px;
}

.content {
  padding-left: 27px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 16px;
  color: $color-red;
  margin: 1rem 0 0;
  display: flex;
  align-items: center;
  padding: 0 10px 0 24px;

  span {
    margin-left: 6px;
  }

  svg:last-child {
    margin-left: auto;
  }
}

.goals {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.goalsTitle {
  font-size: 12px;
  border-bottom: 1px dashed $color-red;
  padding-bottom: 2px;
  font-size: 9px;
  font-weight: 600;
  line-height: 10px;
  color: #000;
  text-align: right;
  width: 145px;
  margin-bottom: 8px;
  margin-left: 32px;

  &.goalsTitleSecond { margin-top: -5px }
}

.text {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  white-space: pre-line;
  margin: 70px 0 0;
  color: $color-red;
  max-width: 75%;
}

.world {
  margin-top: 20px;
}

.next {
  padding-left: 8px;
  margin-top: 8px;
  color: $color-red;
  
  span {
    flex-basis: 0;
    min-width: 175px;
    line-height: 20px;
  }
}

.logoFooter {
  margin-top: 46px;
  margin-left: 116px;
}
@import "styles/imports";

$color-white: #fff;

.tooltip {}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: #2E3348;
  opacity: 0.33;
}

.tooltipContainer {
  position: absolute;
  z-index: 101;
  width: 436px;
  height: 378px;
  background: #FCFCFC;

  :global(.tooltipImpressum) & {
    right: -342px;
    width: 436px;
    height: 360px;
    bottom: 45px;
  }

  :global(.tooltipGoal) & {
    width: 436px;
    height: 283px;
    left: -455px;
    top: -142px;
  }

  :global(.tooltipMilestone) & {
    width: 436px;
    min-height: 150px;
    height: auto;
    top: 70px;
    left: -335px;
  }

  .tooltipInverse:global(.tooltipMilestone) & {
    left: auto;
    right: -344px;
  }

  :global(.tooltipMilestoneTop) & {
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    padding: 0 20px;
    border: 1px solid #000;

    span {
      font-size: 11px;
      font-weight: 400;
      line-height: 21px;
      white-space: nowrap;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -10px;
    bottom: -14px;
    left: 50%;
    border-style: solid;
    border-width: 20px 12px 0 12px;
    border-color: #ffffff transparent transparent transparent;
    
    :global(.tooltipImpressum) & {
      left: 80px
    }

    :global(.tooltipGoal) & {
      right: -21px;
      top: 50%;
      bottom: auto;
      left: auto;
      transform: rotate(270deg);
    }

    :global(.tooltipMilestone) & {
      bottom: auto;
      top: -20px;
      right: 80px;
      left: auto;
      transform: rotate(180deg);
    }
    .tooltipInverse:global(.tooltipMilestone) & {
      left: 80px;
      right: auto;
    }

    :global(.tooltipMilestoneTop) & {
      bottom: -10px;
      left: 50%;
      transform: translateX(50%);
      border-width: 10px 6px 0 6px;
      border-color: #000 transparent transparent transparent;
      z-index: 1;
    } 
  }
  
  :global(.tooltipMilestoneTop) &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 5px 0 5px;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    bottom: -9px;
    transform: translateX(50%);
    left: 50%;
    margin-left: -8px;
    z-index: 2;
  }
}

.close {
  position: absolute;
  width: 24px;
  height: 24px;
  right: -33px;
  top: 0;
  cursor: pointer;
}
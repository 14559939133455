@import "styles/imports";

.wrap {
  position: relative;
}

.controls {
  position: fixed;
  bottom: 5%;
  left: 50%;
  background: white;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  border-radius: 4px;
  display: flex;
  align-items: center;
  
  span {
    font: inherit;
    font-size: 0.8em;
    min-width: 44px;
    padding: 0 1em;
  }
  
  button {
    width: 44px;
    min-width: 44px;
    height: 44px;
    background: white;
    border: 0;
    font: inherit;
    font-size: 1.2em;
    border-radius: 4px;

    &:enabled:hover {
      cursor: pointer;
    }

    &:enabled:hover,
    &:enabled:focus {
      background-color: #e6e6e6;
    }

    &.prevBtn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.nextBtn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.download {
  min-width: 44px;
  cursor: pointer;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 4px;

  &:hover {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #e6e6e6;
  }

  svg {
    opacity: .75;
    width: 20px;
    height: 20px;
  }
}

.pageWrap {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
}
@import "styles/imports";
@import "components/Icon/icons-states.scss";

.wrap {
  padding-left: 100px;
  padding-right: 76px;
  position: relative;
  margin-top: -27px;
  z-index: 2;
  display: flex;
  justify-content: center;
}

.leftCol {
  min-width: 320px;
  margin-right: 60px;
  max-width: 480px;
  flex-basis: 0;
  flex-grow: 1;
}

.title {
  font-size: 28px;
  font-weight: 400;
  line-height: 37px;
  color: $color-red;
  margin: 0 0 20px;
  display: flex;
  align-items: center;

  &.titlePlanet {
    color: $color-planet-green;
    .back { @extend .icon_arrow_green; }
    &:hover .back { @extend .icon_arrow_green_hover; }
  }

  &.titleCulture {
    color: $color-culture-orange;
    .back { @extend .icon_arrow_orange; }
    &:hover .back { @extend .icon_arrow_orange_hover; }
  }

  &.titleStrategy {
    color: $color-strategy-blue;

    .back { @extend .icon_arrow_blue; }
    &:hover .back { @extend .icon_arrow_blue_hover; }
  }
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: $color-black;
  margin: 0 0 18px;
}

.subTitle {
  font-size: 13px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: $color-red;
  margin: 0;
  text-transform: uppercase;

  &.subTitlePlanet {
    color: $color-planet-green;
  }
  &.subTitleCulture {
    color: $color-culture-orange;
  }
  &.subTitleStrategy {
    color: $color-strategy-blue;
  }
}

.rightCol {
  min-width: 320px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 480px;
}

.links {
  margin-top: 24px;
  padding-top: 10px;
  border-top: 1px solid $color-red;
  

  &.linksRightAlign {
    justify-content: flex-end;
  }
}

.linksContainer {
  display: flex;
  align-items: flex-start;
}

.linksTitle {
  min-width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
}

.linksCol {
  &.linksColLeft { 
    //margin-left: auto; 
    margin-left: 56px;
  }

  a, div {
    margin-bottom: 10px;
  }
}

.back {
  display: flex;
  align-items: center;
  margin-right: 10px;

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover svg {  
    @extend .icon_arrow_hover;
  }
}

.highlights {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 320px;
}

.highlightsTitle {
  margin: 10px 0 15px;
  font-size: 18px;
  font-weight: 500;
  line-height: 16px;
  color: $color-red;
}

.highlightItem {
  display: flex;
  margin-bottom: 22px;

  &:last-child { margin-bottom: 0 }
}

.highlightItemIcon {
  svg {
    width: 48px;
    height: 48px;
  }
}

.highlightItemContent {
  margin-left: 22px;
}

.highlightItemTitle {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: $color-red;
}

.highlightItemText {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: $color-black;
}

.highlightPngIcon {
  width: 48px;
  height: 48px
}
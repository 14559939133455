@import 'styles/imports';
@import 'components/Icon/icons-states.scss';

.page {
  width: 71.625rem;
  margin: 0 auto;
  padding-top: 10rem;
  display: flex;
}

.content {
  min-width: 30rem;
  margin-right: 30px;
}

.logoLarge {
  height: 80;
  width: 85;
  //left: 175px;
  //top: 161px;
}

.title {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 55px;
  letter-spacing: 0px;
  text-align: left;
  color: $color-red;
  text-transform: uppercase;
  margin: 1.6875rem 0 0;
  white-space: pre-line;
}

.text {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  color: $color-black;
  margin: 1rem 0 0;

  strong {
    display: block;
    margin-top: 1rem;
    font-weight: 700;
  }
}

.cta {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
  color: $color-red;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  margin: 2rem 0 0;
  cursor: pointer;
  
  span {
    min-height: 37px;
    display: flex;
    align-items: center;
    padding-top: 2px;
    border-bottom: 2px solid transparent
  }

  &:hover {
    span {
      border-bottom: 2px solid $color-red
    }

    .ctaArrow {
      @extend .icon_arrow_hover;
    }
  }
}

.ctaArrow {
  max-width: 24px;
  min-width: 24px;
  margin-left: 8px;
}

.logoFooter {
  margin-top: 4.625rem;

  &.logoFooterOutro {
    margin-top: 30px;
  }
}

.introPic {
  margin-left: auto;
  width: 640px;
  height: 600px;
}

.outroPic {
  margin-left: auto;
  width:  720px;
  height: 600px;
}

.introAnimation {}

.roadmap {
  margin-top: 10px
}
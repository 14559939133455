$color-red: #FA1439;
$color-black: #000;

$color-planet-green: #586753;
$color-culture-orange: #D9A367;
$color-strategy-blue: #43647B;

$color-milestone-orange: #E5B279;
$color-milestone-green: $color-planet-green;
$color-milestone-lightblue: #ACC4C9;

$font-primary: "Antarctica", sans-serif;
$font-secondary: "Calibri", sans-serif;

$font-size-0: .75rem;
$font-size-1: 1rem;
$font-size-2: 1.25rem;
$font-size-3: 1.75rem;

$pad-10: 0.625rem;
$pad-16: 1rem;
$pad-20: 1.25rem;
$pad-30: 1.875rem;
$pad-23: 1.4375rem;
$pad-32: 2rem;
$pad-40: 2.5rem;

//narrowest version: 1440 x 1024 px – center part 900 px wide, sidebars 270 px each
//widest version: 1920 x 1080 px – center part 1200 px, sidebars 360 px each

$breakpoints: (
  "mobile": (
    max-width: 1440px
  ),  
  "desktop": (
    min-width: 1440px
  ),
  "widest": (
    min-width: 1920px
  )
);
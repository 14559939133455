@import "styles/imports";

$color-white: #fff;

.modal {}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: #2E3348;
  opacity: 0.33;
}

.modalContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  
  :global(.videoModal) & {}
}

.content {
  width: 95vw;
  max-width: 95vw;
  height: 95vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #FCFCFC;
  max-height: 95vh;

  :global(.videoModal) & {
    width: 960px;
    height: 540px; 
    overflow: hidden;
  }

  .value_behaviour & {
    width: 70vw;
    height: 80vh;
    max-width: 70vw;
    max-height: 80vh;
  }
}

.close {
  position: absolute;
  width: 24px;
  height: 24px;
  right: -33px;
  top: 0;
  cursor: pointer;
}
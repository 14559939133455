@import 'styles/imports';

.wrap {
  position: absolute;
  z-index: 1;
  top: -58px;
  bottom: 0;
  left: 35px;
  right: 35px; 
  height: 806px;
  overflow: hidden;
  padding-right: 15px;
}

.section {
  height: 220px;
  display: flex;
  position: relative;

  &.sectionBrand {
    z-index: 1;
  }

  &.sectionPlanet {
    margin-top: -25px;
    z-index: 2;
  }
  
  &.sectionCulture {
    margin-top: -21px;
    z-index: 3;
  }
  
  &.sectionStrategy {
    margin-top: -21px;
    z-index: 4;

    &:after {
      background-color: #fff;
      content: "";
      bottom: 0;
      left: 0;
      
      position: absolute;
      z-index: 4;

      right: 287px;
      height: 23px;
    }
  }
}
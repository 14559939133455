@import "styles/imports";

.wrap {
  padding: 23px 43px;

  &.wrapGoal {
    display: flex;
    flex-direction: column;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: $color-red;
  margin: 0 0 10px;

  .wrapMilestone.wrap_blue & { color: $color-milestone-lightblue }
  .wrapMilestone.wrap_green & { color: $color-milestone-green; }
  .wrapMilestone.wrap_orange & { color: $color-milestone-orange; }

  display: flex;
  align-items: center;

  svg {
    margin-left: auto;
    min-width: 32px;
    height: 32px;
    margin-top: -6px;
    margin-right: -10px;

    path {
      .wrapMilestone.wrap_blue & { fill: $color-milestone-lightblue }
      .wrapMilestone.wrap_green & { fill: $color-milestone-green; }
      .wrapMilestone.wrap_orange & { fill: $color-milestone-orange; }
    }
  }
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 0 10px;
  white-space: pre-wrap
}

.list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 23px 0 0;
}

.listItem {
  margin-right: 45px;
}

.listLabel {
  font-size: 9px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.03em;
  opacity: .5;
}

.listLink {
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  display: block;
  border-bottom: 1px solid $color-red;
  margin-top: 6px;
  padding-bottom: 2px;
  white-space: nowrap;
}

a.listLink {
  cursor: pointer;
  color: $color-black
}

.goal {
  margin-left: auto;
  margin-bottom: 20px;
}

.textList {
  padding: 0 0 0 10px;
  margin: 0;

  li {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin: 0 0 10px;
  }

  ul {
    padding: 0 0 0 10px;
    margin: 5px 0 0;
  }
}
@import 'styles/imports';

.container {
  &:global(.strategy-exit-active) { animation: containerDown 1.7s ease 0s; }
  &:global(.strategy-exit-done) { transform: translate(0, 350px); }

  &:global(.strategy-enter-active) { animation: containerUp 1.7s ease 0s; }
  &:global(.strategy-enter-done) { transform: translate(0, 0); }
}

.leftSide {
  height: 221px;
  min-width: 905px;
  position: absolute;
  top: -25px;
  left: -4px;
  z-index: 1;

  :global(.strategy-exit-active) & {
    animation: sideUp 1.45s ease 0s;
  }
}

.rightSide {
  position: absolute;
  z-index: 2;
  top: -50px;
  right: -31px;
  max-width: 892px;

  :global(.strategy-exit-active) & {
    animation: sideUp 1.45s ease 0s;
  }
}

.dronesAnimation {
  position: absolute;
  right: 143px;
  top: -89px;
  z-index: 3; 
}

.walfischAnimation {
  position: absolute;
  left: -73px;
  top: -108px;
  z-index: 3;
}

.houseAnimation {
  position: absolute;
  right: -110px;
  bottom: 5px;
  z-index: 3;
}

@keyframes containerDown {
  0% {
    transform: translate(0, 0);
  }
  40% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 350px);
  }
}

@keyframes containerUp {
  0% {
    transform: translate(0, 350px);
  }
  40% {
    transform: translate(0, 350px);
  }
  100% {
    transform: translate(0, 0);
  }
}


@keyframes sideUp {
  0% {
    transform: translate(0, 0);
  }
  40% {
    transform: translate(0, -10px);
  }
  90% {
    transform: translate(0, 0);
  }
}
